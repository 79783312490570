var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('editor',{attrs:{"api-key":"2o3k788xzr1fv0vflhktfehfjcuwq2rlyucs4yolxxvjbrss","init":{
        selector: 'textarea',
       height: 500,
       menubar: false,
      directionality: 'rtl',
       plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount'
       ],
       toolbar:
         'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
     }},model:{value:(_vm.contract.value.contract_right),callback:function ($$v) {_vm.$set(_vm.contract.value, "contract_right", $$v)},expression:"contract.value.contract_right"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('editor',{attrs:{"api-key":"2o3k788xzr1fv0vflhktfehfjcuwq2rlyucs4yolxxvjbrss","init":{
        selector: 'textarea',
       height: 500,
       menubar: false,
      directionality: 'rtl',
       plugins: [
         'advlist autolink lists link image charmap print preview anchor',
         'searchreplace visualblocks code fullscreen',
         'insertdatetime media table paste code help wordcount'
       ],
       toolbar:
         'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help'
     }},model:{value:(_vm.contract.value.contract_left),callback:function ($$v) {_vm.$set(_vm.contract.value, "contract_left", $$v)},expression:"contract.value.contract_left"}})],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"md":"12"}},[(!_vm.loadingSubmit)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.savaContract}},[_vm._v(" Save Contract ")]):_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.savaContract}},[_c('spinner-loading',{attrs:{"text":"loading"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }